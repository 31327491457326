import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import Layout from '../../layouts'
import SearchBox from './SearchBox'

export default class Anfahrt extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.calculateRoute = this.calculateRoute.bind(this)
    this.initMap = this.initMap.bind(this)
  }

  calculateRoute(query) {
    if (query) {
      this.directionsService.route(
        {
          origin: query,
          destination: 'Wank 3, 87484 Nesselwang',
          travelMode: this.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === this.maps.DirectionsStatus.OK) {
            this.directionsDisplay.setDirections(result)
            this.marker.setMap(null)
          } else {
            this.marker.setMap(this.map)
            console.error(`error fetching directions`, result)
          }
        }
      )
    }
  }

  initMap({ map, maps }) {
    if (map && maps) {
      this.maps = maps
      this.map = map
      this.directionsService = new maps.DirectionsService()
      this.directionsDisplay = new maps.DirectionsRenderer()
      this.directionsDisplay.setMap(map)

      this.marker = new maps.Marker({
        position: { lat: 47.615148, lng: 10.52047 },
        title: 'Ferienwohnung Allgaier',
      })
      this.marker.setMap(map)
    }
  }

  render() {
    return (
      <Layout>
        <div>
          <h2>Ihr Weg zu unserer Ferienwohnung</h2>
          <p>
            Von der A7 kommend verlassen Sie die Autobahn an der Ausfahrt 137,dann rechts abbiegen Richtung Nesselwang. Den Kreisverkehr an der 3. Ausfahrt
            Richtung Nesselwang verlassen. Auf der B309 durch Nesselwang hindurchfahren. Ca. 1 km nach dem Ortsende von Nesselwang rechts abbiegen nach Wank.
          </p>
          <SearchBox onChange={this.calculateRoute} />
          <div style={{ height: '500px', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                language: 'de',
                key: 'AIzaSyAGkZ_6tiR0ODHcIuPvTE_7kwxU9kH65lU',
              }}
              options={maps => ({
                mapTypeId: maps.MapTypeId.HYBRID,
              })}
              defaultCenter={{ lat: 47.615148, lng: 10.52047 }}
              defaultZoom={17}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={this.initMap}
            />
          </div>
        </div>
      </Layout>
    )
  }
}
