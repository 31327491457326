import React, { Component } from 'react'
import style from './searchbox.module.css'

export default class SearchBox extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.calculateRoute = this.calculateRoute.bind(this)
  }
  calculateRoute(e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.query) {
      this.props.onChange(this.state.query)
    }
  }
  render() {
    return (
      <form className={style.searchbox} onSubmit={this.calculateRoute}>
        <input
          type="text"
          placeholder="Startadresse"
          onChange={e => this.setState({ query: e.target.value })}
        />
        <button type="submit">Route anzeigen</button>
      </form>
    )
  }
}
